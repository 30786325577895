import React, { useContext, useEffect } from 'react';
import * as S from './ToggleContainer.styles';
import FunnelContext from '../../../context/FunnelContext';
import useLocalStorage from '../../../hooks/useLocalStorage';

const ToggleContainerMultiple = props => {
  const {
    builderBlock,
    children,
    activeColor,
    hoverColor,
    containers,
    showTickbox,
    tickboxColor,
    tickboxColorActive,
    direction,
    tickboxPositionTop,
    tickboxPositionLeft,
    tickboxRadius
  } = props;

  const {
    extraObjects: extraData,
    setExtraObjects,
    answers: answersContext,
    setAnswers,
    setCurrentTubIndex,
    setCurrentCategory
  } = useContext(FunnelContext);

  const [active, setActive] = useLocalStorage(
    'toggleContainerMultipleActive',
    ''
  );
  const [extraObjects2, setExtraObjects2] = useLocalStorage('extraObjects', {});
  const [answers2, setAnswers2] = useLocalStorage('answers', {});
  const [currentTubIndex2, setCurrentTubIndex2] = useLocalStorage(
    'currentTubIndex',
    null
  );
  const [currentCategory2, setCurrentCategory2] = useLocalStorage(
    'currentCategory',
    null
  );

  useEffect(() => {
    const newExtraData = Object.assign(extraData, extraObjects2);
    setExtraObjects(newExtraData);
    setExtraObjects2(newExtraData);

    const newAnswerData = Object.assign(answersContext, answers2);
    setAnswers(newAnswerData);
    setAnswers2(newAnswerData);

    if (currentTubIndex2 || currentTubIndex2 === 0) {
      setCurrentTubIndex(currentTubIndex2);
    }
    if (currentCategory2) {
      setCurrentCategory(currentCategory2);
    }
  }, [
    containers,
    extraData,
    setActive,
    builderBlock,
    extraObjects2,
    setExtraObjects,
    setExtraObjects2,
    answersContext,
    setAnswers,
    answers2,
    setAnswers2,
    currentTubIndex2,
    currentCategory2,
    setCurrentTubIndex,
    setCurrentCategory
  ]);

  const noChildren = () => {
    return <S.NoChildren>insert components here</S.NoChildren>;
  };

  const handleClick = (
    index,
    { extraObjects, setAnswer, currentTubIndex, currentCategory }
  ) => {
    const newExtraData = extraData;
    const newAnswerData = answersContext;

    Object.assign(newExtraData, { [builderBlock.id]: index });

    extraObjects.map(({ key, value }) => {
      Object.assign(newExtraData, { [key]: value });
      Object.assign(newAnswerData, { [key]: value });
      return null;
    });

    if (setAnswer) {
      setAnswers(newAnswerData);
      setAnswers2(newAnswerData);
    }

    setExtraObjects(newExtraData);
    setExtraObjects2(newExtraData);
    setActive(index);

    if (currentTubIndex || currentTubIndex === 0) {
      setCurrentTubIndex(currentTubIndex);
      setCurrentTubIndex2(currentTubIndex);
    }

    if (currentCategory) {
      setCurrentCategory(currentCategory);
      setCurrentCategory2(currentCategory);
    }
  };

  const Container = () => {
    return containers.map((container, index) => {
      return (
        <S.Wrapper
          count={containers.length}
          direction={direction ?? `column`}
          key={index}
        >
          <S.Container
            onClick={() => handleClick(index, container)}
            active={active === index}
            hoverColor={hoverColor}
            activeColor={activeColor}
          >
            {showTickbox && (
              <S.Dot
                className="toggle-container-dot"
                tickboxRadius={tickboxRadius}
                active={active === index}
                tickboxColor={tickboxColor}
                tickboxColorActive={tickboxColorActive}
                tickboxPositionTop={tickboxPositionTop}
                tickboxPositionLeft={tickboxPositionLeft}
              />
            )}
            {children[index] || noChildren()}
          </S.Container>
        </S.Wrapper>
      );
    });
  };

  return <Container />;
};

export default ToggleContainerMultiple;
